document.addEventListener('show.bs.collapse', function (event) {
    // allow for a smooth change while in transition
    const targetElement = event.target.closest('[data-autoheight]');
    targetElement.style.height = 'auto';
})
document.addEventListener('shown.bs.collapse', function (event) {
    // have other elements autoheight after completing the transition
    $("body").autoHeightItems()
})

document.addEventListener('hide.bs.collapse', function (event) {
    // allow for a smooth change while in transition
    const targetElement = event.target.closest('[data-autoheight]');
    targetElement.style.height = 'auto';
})

document.addEventListener('hidden.bs.collapse', function (event) {
    // have other elements autoheight after completing the transition
    $("body").autoHeightItems()
})
